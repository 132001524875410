import Heading from 'src/components/Heading';
import Image from 'src/components/Image';
import PropTypes from 'prop-types';
import React from 'react';
import Tag from 'src/components/typography/Tag';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';
import { above, below } from 'src/components/global/mediaqueries';

const Wrapper = styled('div')`
    position: relative;

    ${below.tablet_s} {
        padding-bottom: 125%;
    }

    ${above.tablet_s} {
        height: 90vh;
    }
`;

const ImageWrapper = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const HeroImage = styled(Image)`
    width: 100%;
    height: 100%;
    transform: scale(1.1);

    &.standard {
        filter: blur(${theme.blur.standard.desktop});
    }
    ${below.tablet_s} {
        &.standard {
            filter: blur(${theme.blur.standard.mobile});
        }
    }
`;

const TextWrapper = styled('div')`
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const TextInnerWrapper = styled('div')`
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    ${theme.spacing.mobile._64('padding-bottom')};
    ${theme.spacing.tablet._120('padding-bottom')};
    ${theme.spacing.desktop._88('padding-bottom')};
`;

const HeroTag = styled(Tag)`
    text-align: center;
    ${theme.spacing.mobile._16('padding-bottom')};
    ${theme.spacing.tablet._32('padding-bottom')};
    ${theme.spacing.desktop._32('padding-bottom')};
`;

const HeroHeading = styled(Heading)`
    ${theme.fontSize.sans.mobile._40};
    ${theme.fontSize.sans.tablet._40};
    ${theme.fontSize.sans.desktop._80};

    margin: 0;
    font-style: normal;
    font-weight: normal;
    line-height: 115%;
`;

const HeroModuleAbout = ({ data }) => {
    const { backgroundImage, blurAmount, heading, tag, textColor } = data;

    const imageSize = blurAmount === 'none' ? 'large' : 'small';
    const heroTextColor = theme.color[textColor] || theme.color.black;

    const images =
        backgroundImage.mobile.image[`${imageSize}`] && backgroundImage.desktop.image[`${imageSize}`]
            ? [
                  backgroundImage.mobile.image[`${imageSize}`][0],
                  {
                      ...backgroundImage.desktop.image[`${imageSize}`][0],
                      media: `${above.tablet_s.replace('@media ', '')}`,
                  },
              ]
            : backgroundImage.desktop.image[`${imageSize}`]
            ? backgroundImage.desktop.image[`${imageSize}`]
            : backgroundImage.mobile.image[`${imageSize}`];

    return (
        <Wrapper>
            <ImageWrapper>
                <HeroImage className={blurAmount} objectFit="cover" fluid={images} />
            </ImageWrapper>
            <TextWrapper>
                <TextInnerWrapper>
                    <HeroTag style={{ color: heroTextColor }}>{tag}</HeroTag>
                    <HeroHeading as="h1" style={{ color: heroTextColor }}>{heading}</HeroHeading>
                </TextInnerWrapper>
            </TextWrapper>
        </Wrapper>
    );
};

export default HeroModuleAbout;

HeroModuleAbout.propTypes = {
    data: PropTypes.shape({
        backgroundImage: PropTypes.object.isRequired,
        blurAmount: PropTypes.string.isRequired,
        heading: PropTypes.string,
        tag: PropTypes.string,
        textColor: PropTypes.string,
    }),
};

HeroModuleAbout.defaultProps = {
    data: {
        backgroundImage: {},
        heading: '',
        tag: '',
    },
};
